.team-card-wrapper {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  overflow: hidden;
  height: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  &.add {
    justify-content: space-between !important;
  }

  .team-card-body {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 17px;
  }

  .hexagon {
    position: absolute;
    width: 30%;
    left: 50%;
    transform: translate(-50%, 25%);
    margin-top: 25px;

    polygon {
      stroke-width: 8;
      stroke: #fff;
    }
  }

  .clickable {
    cursor: pointer;
  }

  & .btn-add-new-team {
    color: #adafca;
    box-shadow: none;
    background-color: #eef2f8;

    &:hover {
      background-color: #efa107;
      color: #fff;
    }
  }

  .team-card-content {
    padding: 15px;
    margin-top: 40px;

    .title {
      color: #3e3f5e;
      font-weight: 700;
      font-size: 1.125rem;
    }

    .role {
      font-size: 0.6875rem;
      color: #adafca;
      font-weight: 700;
      text-transform: uppercase;
    }

    .workspaces {
      font-size: 14px;
      margin-top: 18px;
      margin-bottom: 0;
      color: var(--secondary-color);
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
    }

    & .btn-team-action {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        margin: 0;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        margin-top: 10px;
        color: #adafca;
        font-size: 0.6875rem;
      }

      & .green {
        background-color: #00e194;
      }

      & .purple {
        background-color: #7289da;
      }

      & .red {
        background-color: #fd434f;
      }

      & .orange {
        background-color: #eb9e57;
      }

      button {
        width: 40px;
        height: 40px;
        transition:
          transform 0.2s ease-in-out,
          -webkit-transform 0.2s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          transform: translate(0, -4px);
        }

        & svg {
          width: 15px;
          height: 15px;
          fill: #fff;
        }
      }
    }
  }

  .team-card-header {
    height: 70px;
    width: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;

    &.add-new-team {
      background: url('../../assets/images/background/grey.png');
    }

    &.active {
      background: url('../../assets/images/background/green.png');
    }

    &.disabled {
      background: url('../../assets/images/background/red.png');
    }
  }
}

.user-stats {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;

  .user-stat {
    position: relative;
    padding: 0 10px;

    &::after {
      content: '';
      width: 1px;
      height: 20px;
      background-color: #eaeaf5;
      position: absolute;
      top: 6px;
      right: 0;
    }

    &:last-child::after {
      display: none;
    }

    .user-stat-text {
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      margin-top: 10px;
      color: #adafca;
      font-size: 0.6875rem;
    }
  }
}