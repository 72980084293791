// main {
//   background: url('../../assets/images/background/landing-background.png');
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: left center;
//   overflow-x: hidden;
//   height: 100vh;

//   .landing-info {
//     h1,
//     h2,
//     p {
//       color: #fff;
//       text-align: center;
//       text-transform: uppercase;
//     }

//     h2 {
//       font-size: 1.5rem;
//       font-weight: 500;
//     }

//     h1 {
//       font-family: 'Titillium Web', sans-serif;
//       font-size: 6.5rem;
//       font-weight: 900;

//       @media (max-width: 500px) {
//         font-size: 3.75rem;
//       }
//     }

//     p {
//       font-size: 1.125rem;
//       line-height: 1.3333333333em;
//       text-transform: initial;
//     }
//   }
// }

.container-checkout-plan {
  max-width: 1400px;
  max-height: 1050px;
  margin-top: 55px;
  margin-bottom: 55px;
}
