.theme-lugarh {
  button {
    box-shadow: initial;
    border: none;
    height: initial;
    cursor: pointer;
  }

  .btn {
    &__primary {
      border-radius: 4px;
      background-color: var(--primary-color);
      padding: 17px 34px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      gap: 16px;
      color: white;
      @extend .default-font;

      &:hover {
        background-color: var(--primary-color-light);
      }

      &:active {
        background-color: var(--primary-color-dark);
      }

      &:disabled {
        color: #ffffffcc;
      }

      &--add {
        background-color: var(--tertiary-color);

        &:hover {
          background-color: var(--tertiary-color-light);
        }

        &:active {
          background-color: var(--tertiary-color-dark);
        }
      }
    }

    &__secondary {
      width: 100%;
      height: 48px;
      padding: 12px 16px;
      gap: 8px;
      border-radius: 4px;
      background-color: white;
      box-shadow: 0px 0px 4px 0px #95009930;
      color: var(--text-primary-color);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: var(--enable-border-color);
      }

      &:active {
        background-color: var(--primary-color);
        color: white;

        svg {
          path {
            stroke: white;
          }
        }
      }
    }

    &__tirtiary {
      width: 100%;
      height: 40px;
      padding: 10px 16px;
      gap: 8px;
      border-radius: 0;
      background-color: var(--surface-information-color);
      color: var(--text-primary-color);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: var(--lighter-grey-color);
      }

      &:active {
        background-color: var(--medium-light-grey-color);
      }

      &:disabled {
        color: var(--lighter-grey-color);

        &:hover {
          background-color: var(--surface-information-color);
        }

        &:active {
          background-color: var(--surface-information-color);
        }
      }
    }

    &__icon-button {
      width: auto;
      height: 40px;
      padding: 16px 8px;
      border-radius: 50%;
      gap: 8px;
      color: var(--text-primary-color);
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #d1f6f6;
      }

      &:active {
        background-color: #d1f6f6;
      }

      &:disabled {
        opacity: 0.3;

        &:hover {
          background-color: transparent;
        }

        &:active {
          background-color: transparent;
        }
      }
    }

    &__password-show {
      background-color: transparent;
      color: var(--text-primary-color);
      opacity: 0.5;
      font-size: 12px;
      font-weight: 700;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 15px;
      top: 15px;
      width: initial;
    }
  }
}