$box-shadow-default: 0px 3px 1px -2px #00000033;
$box-shadow-hover: 0px 4px 5px 0px #00000024;

.theme-lugarh {
  .theme-button {
    width: fit-content;
    min-width: fit-content;
    height: 48px;
    gap: 8px;

    .MuiButton-icon {
      margin-right: initial;
    }

    svg {
      width: 24px;
    }

    &--primary, &--primary-blue {
      background-color: var(--tertiary-color);
      box-shadow: $box-shadow-default;
      color: white;

      &:hover {
        background-color: var(--tertiary-color-dark);
        box-shadow: $box-shadow-hover;
      }

      &:active {
        background-color: var(--tertiary-color);
        box-shadow: $box-shadow-hover;
      }

      &:disabled {
        background-color: var(--lighter-grey-color);
        box-shadow: initial;
      }
    }

    &--primary-blue {
      background-color: var(--secondary-color);

      &:not(:disabled) {
        &:hover {
          background-color: var(--lighter-grey-color);
        }
      }

      &:active {
        background-color: var(--secondary-color);
      }
    }

    &--secondary {
      box-shadow: $box-shadow-default;
      background-color: var(--secondary-color);
      height: 36px;
      color: white;

      &:hover {
        background-color: var(--secondary-color-dark);
        box-shadow: $box-shadow-hover;
      }

      &:active {
        background-color: var(--secondary-color);
        box-shadow: $box-shadow-hover;
      }

      &:disabled {
        background-color: var(--lighter-grey-color);
        box-shadow: initial;
      }
    }

    &--outlined {
      background-color: transparent;
      border: 1px solid var(--secondary-color);
      color: var(--secondary-color);

      &:hover, &:active {
        background-color: var(--secondary-color);
        color: white;
      }

      &:disabled {
        background-color: transparent;
        border-color: var(--lighter-grey-color);
      }
    }

    &--text-only {
      color: var(--secondary-color);
      height: 28px;

      &:disabled {
        background-color: transparent;
      }
    }

    &:disabled {
      color: var(--medium-grey-color);
      cursor: not-allowed;
      pointer-events: auto;
    }
  }
}
