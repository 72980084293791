.theme-lugarh {
  &.mui-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiModal-backdrop {
      backdrop-filter: blur(8px);
    }

    &--right {
      .mui-modal__box.MuiBox-root {
        position: fixed;
        right: 0;
        height: 100%;
        border-radius: 0;
        padding-top: 50px;
      }
    }

    &--navigable {
      .mui-modal__box.MuiBox-root {
        position: fixed;
        right: 0;
        height: 100%;
        width: 90%;
        border-radius: 0;
      }

      div.mui-modal__close-btn {
        top: 11px;
        left: 18px;
        right: 0;
        width: fit-content;
      }
    }

    .mui-modal__box {
      background-color: white;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .mui-modal__close-btn {
      position: absolute;
      top: 16px;
      right: 18px;
      cursor: pointer;
      z-index: 11;
    }

    .mui-modal__header {
      padding: 16px;
      flex: 0 0 auto;
    }

    .mui-modal__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.575;
      color: var(--text-color-primary);
      margin-bottom: 4px;
    }

    .mui-modal__subtitle {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
    }

    .mui-modal__nav {
      padding: 16px;
      border-top: 1px solid #b9a0b931;
      border-bottom: 1px solid #b9a0b931;
    }

    .mui-modal__steps {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;

      li::marker {
        content: none;
      }
    }

    .mui-modal__step {
      font-size: 14px;
      font-weight: 600;
      margin-right: 52px;
      position: relative;

      &--active {
        color: var(--secondary-color);
      }

      &--done {
        color: var(--tertiary-color-dark);
      }

      &--clickable {
        cursor: pointer;
      }

      &:first-child {
        &::after {
          content: '●';
          position: absolute;
          top: 50%;
          right: -30px;
          transform: translateY(-50%);
          color: var(--text-color-primary);
        }
      }
    }

    .mui-modal__content {
      border-radius: 0 0 4px 4px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex: 1 1 auto;
      overflow: hidden;
    }

    .mui-modal__content-container {
      flex: 1 1 auto;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .mui-modal__form {
      padding: 21px 18px;
    }

    .mui-modal__footer {
      padding: 16px;
      flex: 0 0 auto;

      .mui-modal__unique-button {
        margin: 0;
      }
    }
  }
}
