/*------------------------------------------------------------------
[Table of contents]

1. .dropdown-box
2. .dropdown-box-category
3. .dropdown-box-list
4. .dropdown-box-actions
5. .dropdown-box-button
-------------------------------------------------------------------*/
/*----------------------
    1. DROPDOWN BOX
----------------------*/

.dropdown-box {
  width: 384px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 5px 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;

  &.padding-bottom-small {
    padding-bottom: 14px;
  }

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  .dropdown-box-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 28px 28px 20px;

    .dropdown-box-header-title {
      font-size: 1rem;
      font-weight: 700;

      .highlighted {
        color: #00c7d9;
      }
    }

    .dropdown-box-header-actions {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: end;
      align-items: flex-end;

      .dropdown-box-header-action {
        margin-right: 16px;
        color: #8f91ac;
        font-size: 0.75rem;
        font-weight: 700;
        opacity: 0.6;
        cursor: pointer;

        &:hover {
          color: #00c7d9;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .dropdown-box-category {
    padding-top: 20px;

    &:first-child {
      padding-top: 28px;
    }
  }

  .dropdown-box-button {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .dropdown-box-actions:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

/*------------------------------
    2. DROPDOWN BOX CATEGORY
------------------------------*/
.dropdown-box-category {
  padding: 0 28px 4px;

  .dropdown-box-category-title {
    color: #adafca;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }
}

/*----------------------------
    3. DROPDOWN BOX LIST
----------------------------*/
.dropdown-box-list {
  height: 420px;
  overflow-y: auto;

  &.no-scroll {
    height: auto;
  }

  &.scroll-small {
    height: 286px;
  }

  &.medium {
    .dropdown-box-list-item {
      padding: 20px 28px;
    }
  }

  &.small {
    .dropdown-box-list-item {
      padding: 10px 28px;
    }
  }

  &.no-hover {
    .dropdown-box-list-item:hover {
      background-color: transparent;
    }
  }

  .dropdown-box-list-item {
    display: block;
    padding: 16px 28px;

    &.unread {
      background-color: #fcfcfd;
    }

    &:hover {
      background-color: #eaeaf5;
    }
  }
}

/*------------------------------
    4. DROPDOWN BOX ACTIONS
------------------------------*/
.dropdown-box-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 32px 0;
  background-color: #fcfcfd;

  .dropdown-box-action {
    margin-right: 16px;

    .button {
      width: 156px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

/*------------------------------
    5. DROPDOWN BOX BUTTON
------------------------------*/
.dropdown-box-button {
  width: 100%;
  height: 60px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #3e3f5e;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
  line-height: 60px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    color: #fff;
  }

  &.primary {
    background-color: #23d2e2;

    &:hover {
      background-color: #1bc5d4;
    }
  }

  &.secondary {
    background-color: #615dfa;

    &:hover {
      background-color: #5753e4;
    }
  }
}

/*------------------------------------------------------------------
[Table of contents]

1. .dropdown-navigation
-------------------------------------------------------------------*/
/*----------------------------
    1. DROPDOWN NAVIGATION
----------------------------*/
.dropdown-navigation {
  width: 220px;
  padding: 20px 28px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 5px 40px 0 rgba(94, 92, 154, 0.06);

  .dropdown-navigation-header + .dropdown-navigation-category {
    margin-top: 30px;
  }

  .dropdown-navigation-category {
    margin: 20px 0 10px;
    color: #adafca;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .dropdown-navigation-link {
    display: block;
    padding: 8px 0;
    color: #3e3f5e;
    font-family: 'Quicksand', sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    transition:
      padding 0.2s ease-in-out,
      color 0.2s ease-in-out;

    .highlighted {
      float: right;
      color: #00c7d9;
    }

    &:hover {
      padding-left: 4px;
    }
  }

  .dropdown-navigation-button {
    width: 100%;
    margin-top: 20px;
  }

  /*--------------------
    MEDIA QUERIES
  --------------------*/
  @media screen and (max-width: 1366px) {
    .dropdown-navigation-category {
      font-size: 0.6875rem;
    }
    .dropdown-navigation-link {
      font-size: 0.75rem;
      padding: 6px 0;
    }
  }
}
