.theme-lugarh{
    .no-result{
        &__content{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          gap: 48px;
          margin-top: 43px;
        }
        &__text{
          max-width: 380px;
          height: auto;
    
          &--subtitle{
            color: var(--dark-grey-color);
          }
        }

        &__buttons{
          display: flex;
          flex-direction: column;
          gap: 20px;

          &--changeWorkspace-button{
            color: var(--primary-color);
        }
        }
      }
}