@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.theme-lugarh {
  color: var(--text-color-primary);
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  // RESET
  p, a, h1, h2, h3, h4, h5, h6, ul, ol, li {
    @extend .default-font;
  }

  h1, h2, h3, h4, h5, h6 {
    display: block;
    width: 100%;
  }

  .default-font {
    font-family: 'Montserrat', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 400;
    color: var(--text-color-primary);
  }

  .special-font {
    font-family: 'Comfortaa', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }

  .txt-center {
    text-align: center;
  }

  .txt-left {
    text-align: left;
  }

  .txt-right {
    text-align: right;
  }

  .txt-s {
    font-size: 12px;
    line-height: 2;
  }

  .txt-m {
    font-size: 14px;
    line-height: 2;
  }

  .txt-normal {
    font-size: 16px;
    line-height: 1.5;
  }

  .txt-ml {
    font-size: 18px;
    line-height: 2;
  }

  .txt-l{
    font-size: 20px;
    line-height: 1.5;
  }

  .txt-xl {
    font-size: 24px;
    line-height: 2;
  }

  .txt-bold {
    font-weight: 700;
  }

  .txt-semi-bold {
    font-weight: 500;
  }

  .txt-secondary {
    opacity: 0.6;
  }
}
