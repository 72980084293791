.theme-lugarh {
  &.ce-modal {
    .mui-modal {
      &__box {
        width: 500px;
        height: 580px;
        position: relative;
      }

      &__content {
        height: 502px;
        overflow-y: scroll;
      }

      &__form {
        border-radius: initial;
        height: -webkit-fill-available;

        .form__field {
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    .ce-modal__list {
      margin-bottom: 90px;
      padding-bottom: 0;
    }

    .ce-modal__footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 0 0 4px 4px;
      background-color: white;
      padding: 20px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;

      .form__button {
        background-color: var(--primary-color);
        margin: 0;
      }

      .ce-modal__link {
        width: -webkit-fill-available;
      }
    }
  }
}
