.dashboard-card-wrapper {
  background: var(--background-color-light);
  box-shadow:
    0px 4px 4px rgba(50, 50, 71, 0.08),
    0px 4px 8px rgba(50, 50, 71, 0.06);
  border-radius: 10px;
  padding: 25px;
  width: 100%;
  height: 97%;
  margin: 10px 0;

  & .center {
    margin: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }

  .dashboard-card-select {
    display: flex;
    justify-content: space-between;
  }

  p {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000;
    margin: 0;
  }
}