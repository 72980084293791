.list-jobs {
  display: grid;
  grid-gap: 30px;
  grid-template-areas: 'action action action action';
  grid-template-columns: repeat(auto-fit, 262px);
  justify-content: center;
}

.loader-container {
  // margin-top: 20px;
}
