@import '../../assets/styles/variables';

.header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: $secondary-color;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;

  .header-avatar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-right: 40px;

    &__avatar {
      width: 36px;
      height: 36px;

      .MuiAvatar-circular {
        border: solid 1px;
      }

      &--admin {
        .MuiAvatar-circular {
          border-color: #d8fe04;
        }
      }

      &--manager {
        .MuiAvatar-circular {
          border-color: var(--tertiary-color);
        }
      }

      &--recruiter {
        .MuiAvatar-circular {
          border-color: var(--secondary-color);
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;

      &-name {
        font-weight: bold;
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        margin-bottom: 1px;
      }

      &-company {
        font-size: 12px;
        font-weight: 500;
        line-height: 14.63px;
        text-align: left;
        opacity: 0.4;
      }
    }
  }

  .header-actions {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;

    &.search-bar {
      width: 80%;
      position: relative;

      .header-search-dropdown {
        width: 100%;
      }
    }

    .progress-stat {
      width: 110px;
    }

    .register-button {
      padding: 0 26px;
      margin: 0 30px;
    }

    .login-form {
      margin: 0 14px 0 12px;

      .button {
        width: 52px;
        height: 52px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }
  }

  .header-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;

    .logo {
      margin-left: 26px;

      a {
        display: flex;
      }
    }

    .header-brand-text {
      margin-left: 26px;
      color: #fff;
      font-family: 'Titillium Web', sans-serif;
      font-size: 1.25rem;
      font-weight: 900;
      text-transform: uppercase;
    }
  }

  .sidemenu-trigger,
  .mobilemenu-trigger {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 80px;
    height: 80px;
    cursor: pointer;
  }

  .sidemenu-trigger .active .icon-grid,
  .sidemenu-trigger:hover .icon-grid {
    fill: #fff;
  }

  .sidemenu-trigger .icon-grid {
    pointer-events: none;
    transition: fill 0.3s ease-in-out;
  }

  .mobilemenu-trigger {
    display: none;
  }

  .input-search {
    width: 100%;
  }

  .action-list {
    height: 80px;

    &::before,
    &::after {
      top: 24px;
    }
  }

  .action-item {
    width: 80px;
    height: 80px;
  }
}

.icon-info {
  fill: #adafca;
  width: 20px;
  height: 20px;
}

.headers_options {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: flex-start;
  height: 100px;
  flex-direction: row;
}

.indicate-btn a {
  margin-right: 10px;
  color: white;
  cursor: pointer;
}

/*-----------------------------
    8. BURGER ICON
-----------------------------*/
.burger-icon {
  width: 20px;
  height: 14px;
  position: relative;

  &.open {
    .burger-icon-bar {
      &:nth-child(1) {
        width: 100%;
      }

      &:nth-child(2) {
        width: 14px;
      }

      &:nth-child(3) {
        width: 10px;
      }
    }
  }

  &.inverted {
    .burger-icon-bar {
      left: 0;

      &:nth-child(1) {
        width: 100%;
      }

      &:nth-child(2) {
        width: 14px;
      }

      &:nth-child(3) {
        width: 10px;
      }
    }
  }

  .burger-icon-bar {
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 0;
    transition: width 0.4s ease-in-out;

    &:nth-child(1) {
      top: 0;
      width: 10px;
    }

    &:nth-child(2) {
      top: 6px;
      width: 14px;
    }

    &:nth-child(3) {
      top: 12px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1500px) {
  .header .header-brand .header-brand-text {
    display: none;
  }
}

@media screen and (max-width: 1365px) {
  .header {
    .header-actions {
      .navigation {
        display: none;
      }

      .progress-stat {
        margin-right: 28px;
      }

      &.search-bar {
        width: 50%;
        margin-right: 26px;
      }
    }

    &.logged-out {
      .header-actions {
        .mobilemenu-trigger {
          display: -ms-flexbox;
          display: flex;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .header {
    &.logged-out {
      .header-actions {
        &:nth-last-child(2) {
          display: block;
        }

        &:last-child {
          display: none;
        }

        .register-button,
        .navigation {
          display: none;
        }
      }
    }

    .header-actions {
      &:nth-last-child(2) {
        display: none;
      }

      .navigation {
        display: none;
      }

      &.search-bar {
        width: 80%;
      }
    }
  }

  .floaty-bar.logged-out {
    display: -ms-flexbox;
    display: flex;
  }
}

@media screen and (max-width: 680px) {
  .header {
    height: 60px;

    .header-actions {
      &:nth-last-child(1) {
        display: none;
      }

      &.search-bar {
        height: 100%;
        margin-right: 0;

        .interactive-input {
          height: 100%;

          input {
            border-radius: 0;
          }
        }
      }

      .header-brand .logo {
        margin-left: 16px;
      }

      .sidemenu-trigger {
        display: none;
      }

      .mobilemenu-trigger {
        display: -ms-flexbox;
        display: flex;
      }
    }
  }

  .floaty-bar {
    display: -ms-flexbox;
    display: flex;
  }
}