.section-header-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  padding: 0 28px;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  background-color: #fff;

  .section-title {
    font-size: 1.625rem;
    font-weight: 700;
    width: 100%;
  }

  .view-actions {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
  }

  .btn-filter {
    width: 196px;
    height: 51px;
    gap: 10px;
    border-radius: 4px;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    height: auto;
    padding: 32px 28px;

    .view-actions {
      margin-top: 20px;
    }
  }
}

.view-type {
  display: flex;
  flex-direction: row;
  justify-content: right;

  svg {
    width: 20px;
    margin: 0 10px;
    cursor: pointer;
    fill: #adafca;
    opacity: 0.4;
    transition:
      opacity 0.2s ease-in-out,
      fill 0.2s ease-in-out;

    &:hover,
    &.active {
      fill: #3e3f5e;
      opacity: 1;
    }
  }
}