.section-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;

  > .select-wrapper {
    width: 40%;
    margin-right: 15px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;

    > .select-wrapper {
      width: 93%;
      margin-right: 0px;
      margin-top: 20px;
    }
  }
}

.section-header-dashboard {
  .section-pretitle {
    color: #8f91ac;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .section-title {
    margin-top: 8px;
    font-size: 1.625rem;
    font-weight: 700;
    highlighted.secondary {
      color: #615dfa;
    }
    &.pinned::before {
      content: 'pinned';
      display: inline-block;
      margin-right: 12px;
      padding: 4px 8px;
      border-radius: 200px;
      background-color: #23d2e2;
      color: #fff;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1em;
      text-transform: uppercase;
      position: relative;
      top: -4px;
    }
  }
}

.section-header-filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .MuiFormLabel-root {
    font-family: 'Quicksand', sans-serif;
    top: -7px;

    &.Mui-focused {
      color: #00000099;
    }
  }

  .MuiInputLabel-shrink {
    top: 0;
  }

  .MuiInputBase-root {
    width: 230px;
    height: 40px;
    border-radius: 5px;
  }

  .MuiSelect-select {
    font-weight: 400;
    font-family: 'Quicksand', sans-serif;
  }

  .Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border: 1px solid #0000003b;
    }
  }

  .select-wrapper {
    label {
      background: var(--surface-information-color);
      color: #00000099;
    }

    &[data-empty='false'] {
      label {
        font-size: 12px;
        font-weight: 400;
      }
    }

    svg {
      margin-top: 17px;
      margin-left: -25px;
    }
  }

  #recruter-select {
    background-color: transparent;
    border-color: #0000003b;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    padding: 0 40px 0 12px;
    width: 230px;
    line-height: 1.5;
  }
}
