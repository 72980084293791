// IMPORTANT: colors variables are managed by the ThemeController at app/src/context/ThemeContex.tsx.
// NOTE: Please do not modify this file directly as it will be overwritten.
// NOTE: Do not use the !important directive in this file.

.theme-lugarh {
  --primary-color: #950099;
  --primary-color-dark: #5a005d;
  --primary-color-light: #cb00d2;
  --primary-surface: #F4EDF5;
  --secondary-color: #0288D1;
  --secondary-color-dark: #01579B;
  --secondary-color-light: #03A9F4;
  --enable-border-color: #00B1B73B;
  --active-border-color: #00B1B7;
  --tertiary-color: #6AC425;
  --tertiary-color-dark: #5EAE21;
  --tertiary-color-light: #7FCD43;
  --error-default: #D32F2F;
  --surface-primary-color: #b9a0b910;
  --surface-information-color:#EAF1FF66;
  --background-color-light: #ffffff;
  --text-primary-color: #141314;
  --lightest-grey-color: #F2F2F2;
  --lighter-grey-color: #E6E6E6;
  --light-grey-color: #CCCCCC;
  --medium-light-grey-color: #B3B3B3;
  --medium-grey-color: #999999;
  --medium-dark-grey-color: #8C8C8C;
  --dark-grey-color: #666666;
  --darker-grey-color: #4D4D4D;
  --darkest-grey-color: #333333;
  --almost-black-color: #1A1A1A;
}
