.theme-lugarh{
 .error-charts{
  align-items: center;
  &__text-error{
    color: var(--dark-grey-color);
    margin-top: 20px;
  }
}
}

